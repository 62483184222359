<template>
  <ContractStatisticsComponent v-if="group === 'contracts'" :rows="rows"></ContractStatisticsComponent>
  <RegularStatisticsComponent v-else :rows="rows"></RegularStatisticsComponent>
</template>

<script>
import {mapActions, mapState, mapMutations} from 'vuex'
import sharedMethods from '../Shared/sharedMethods'
import ContractStatisticsComponent from '../Shared/contractStatistics.vue'
import RegularStatisticsComponent from '../Shared/regularStatistics.vue'

export default {
  name: 'StatsBuyers',
  computed: {
    ...mapState({
      statistics: s => s.stats.buyers.stats,
      group: s => s.stats.buyers.group
    }),
    rows() {
      return this.group === 'contracts'
          ? sharedMethods.getContractRows(this.statistics)
          : sharedMethods.getRegularRows(this.statistics)
    },
  },
  async created() {
    await this.fetch()
  },
  beforeDestroy() {
    this.reset()
  },
  components: {
    RegularStatisticsComponent,
    ContractStatisticsComponent
  },
  methods: {
    ...mapActions({
      fetch: 'stats/buyers/fetch'
    }),
    ...mapMutations({
      reset: 'stats/buyers/reset'
    }),
  }
}
</script>
