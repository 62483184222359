var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.group === "contracts"
    ? _c("ContractStatisticsComponent", { attrs: { rows: _vm.rows } })
    : _c("RegularStatisticsComponent", { attrs: { rows: _vm.rows } })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }